import { Spinner, Flex, Stack, Text, IconButton } from "@chakra-ui/react";
import { Combobox, ProviderRoleBadge } from "components";
import { type UserSimple } from "graphql/__generated__/graphql";
import { BsFillPersonXFill } from "react-icons/bs";
import { useAuthStore } from "stores/auth";

export const SelectUsers: React.FC<{
  users: UserSimple[];
  isLoading: boolean;
}> = ({ users, isLoading }) => {
  const items = users.map(({ email, lastName, firstName, roles }) => ({
    value: email,
    label: `${lastName} ${firstName}`,
    subLabel: (
      <Stack>
        <Text fontSize="x-small" color="neutral.grey.500" title={email}>
          {email}
        </Text>
        <ProviderRoleBadge roles={roles} />
      </Stack>
    ),
  }));

  const isSwitched = localStorage.getItem("X-Switch");

  const [setIsLoggedIn] = useAuthStore((s) => [s.setIsLoggedIn]);

  if (isLoading) return <Spinner />;

  return (
    <Flex gap={3} align="center">
      <Combobox
        items={items}
        onChange={(value) => {
          value && localStorage.setItem("X-Switch", value);
          setIsLoggedIn(false);
          setIsLoggedIn(true);
        }}
        placeholder={"Changer utilisateur"}
        inputWidth={340}
      />

      {isSwitched && (
        <IconButton
          fontSize={"xl"}
          variant="ghost"
          onClick={() => {
            localStorage.removeItem("X-Switch");
            setIsLoggedIn(false);
            setIsLoggedIn(true);
          }}
          aria-label="remove"
          icon={<BsFillPersonXFill />}
        />
      )}
    </Flex>
  );
};
