export const DevIndicator = () => {
  let backgroundColor = "#166534";
  if (import.meta.env.VITE_APP_STAGE === "staging") backgroundColor = "#1e40af";
  if (import.meta.env.VITE_APP_STAGE === "preprod") backgroundColor = "#6b21a8";
  return (
    <div
      role="banner"
      style={{
        position: "fixed",
        top: 25,
        left: -59,
        backgroundColor,
        padding: 5,
        transform: "rotate(-45deg)",
        zIndex: 100,
        color: "#fff",
        fontWeight: "bold",
        fontSize: 12,
        width: 200,
        textAlign: "center",
        textTransform: "uppercase",
      }}
    >
      {import.meta.env.VITE_APP_STAGE}
    </div>
  );
};
